<template>
  <v-form id="login-form" ref="form" @submit.prevent="handleLogin">
    <v-container fluid>
      <v-row class="my-4">
        <v-col>
          <v-img
            :src="require(`@/assets/logo.png`)"
            max-height="20px"
            height="20px"
            contain
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="text-h5 text-center mb-4">
          {{ $trans("login_title") }}
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.email"
            autofocus
            :label="$trans('login_email')"
            :rules="[rules.required]"
            color="primary"
            outlined
            prepend-icon="$account"
            validate-on-blur
            :disabled="loading"
            autocomplete="username"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.password"
            :append-icon="showPassword ? '$eye-on' : '$eye-off'"
            :label="$trans('password')"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            color="primary"
            outlined
            prepend-icon="$password"
            validate-on-blur
            :disabled="loading"
            autocomplete="current-password"
            hide-details="auto"
            @click:append="showPassword = !showPassword"
          />
        </v-col>
        <v-col v-if="error" cols="12">
          <v-alert type="error">
            <p>{{ error }}</p>
            <p
              v-if="displayResendActiveEmail && !isSendingLinkAgain"
              class="underline"
              @click="sendActivateEmail"
            >
              <strong>{{ $trans("resend_activate_email") }}</strong>
            </p>
            <v-progress-circular
              v-if="isSendingLinkAgain"
              size="24"
              indeterminate
              color="primary"
            />
          </v-alert>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex justify-center" cols="12">
          <v-btn
            color="primary"
            form="login-form"
            min-height="56px"
            min-width="147px"
            type="submit"
            depressed
            :disabled="loading"
            :loading="loading"
          >
            {{ $trans("login") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex justify-center text-center grey--text lighten-3"
          cols="12"
        >
          <span class="underline" @click="forgottenPasswordHandler">{{
            $trans("forgotten_password")
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center grey--text lighten-3" cols="12">
          <span>{{ $trans("no_account_question") }} </span>
          <span class="underline" @click="goToRegister">{{
            $trans("sign_up")
          }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  email,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import api from "@/lib/calendesk-js-library/api/api";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import roleActions from "@/calendesk/mixins/roleActions";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";

export default {
  name: "LoginForm",
  mixins: [roleActions],
  data: () => ({
    showPassword: false,
    isSendingLinkAgain: false,
    form: {
      tenant: null,
      email: null,
      password: null,
    },
    loading: false,
    error: null,
    displayResendActiveEmail: false,
    rules: Object.freeze({
      required,
      email,
      minChars,
    }),
  }),
  computed: {
    ...mapGetters({
      getTenantHealthy: "setup/getTenantHealthy",
      firstRouteRedirectName: "auth/getFirstRouteRedirectName",
    }),
  },
  watch: {
    "getDialog.open": function () {
      if (!this.getDialog.open) {
        this.loading = false;
        if (this.getDialog.data && this.getDialog.data.login) {
          this.login();
        }
      }
    },
  },
  methods: {
    ...mapMutations({ setTenantHealthy: "setup/SET_TENANT_HEALTHY" }),
    ...mapActions({
      loginUserAndStoreSession: "auth/loginUserAndStoreSession",
      getUserData: "auth/getUserData",
      getTenant: "setup/getTenant",
      setTenantData: "setup/setTenantData",
    }),
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    async handleLogin() {
      if (!this.$refs.form.validate()) {
        errorNotification("form_is_invalid", null, false);
        return;
      }

      this.loading = true;
      this.error = null;

      this.getTenant(this.form.email.toLowerCase())
        .then(({ data }) => {
          const { length } = data;
          if (length === 1) {
            const tenant = data[0];
            this.form.tenant = tenant.name;
            this.setTenantData(tenant);
            this.login();
          } else if (length > 1) {
            import("../../../components/Dialogs/dialogTypes").then(
              (response) => {
                this.openDialog({
                  type: response.default.CHOOSE_TENANT,
                  title: this.$trans("choose_tenant_dialog_title"),
                  data: {
                    type: "login",
                    companies: [...data],
                  },
                  size: dialogSize.MIDDLE,
                });
              },
            );
          } else {
            this.loading = false;
            this.error = this.$trans("email_or_password_incorrect");
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = this.$trans("email_or_password_incorrect");
        });
    },
    handleRedirectToDashboard() {
      this.getUserData()
        .then(() => {
          this.$router.push({ name: this.firstRouteRedirectName });
        })
        .catch((error) => {
          this.error = this.$trans("error_occurred");
          this.$log.error("Get User Data Error Occurred", error);
          this.loading = false;
        });
    },
    forgottenPasswordHandler() {
      import("../../../components/Dialogs/dialogTypes").then((response) => {
        this.openDialog({
          type: response.default.FORGOTTEN_PASSWORD,
          title: this.$trans("forgotten_password_title"),
          data: {
            email: this.form.email,
          },
        });
      });
    },
    login() {
      this.loading = true;
      this.loginUserAndStoreSession({
        email: this.form.email.toLowerCase(),
        password: this.form.password,
      })
        .then(() => {
          this.handleRedirectToDashboard();
        })
        .catch((error) => {
          this.displayResendActiveEmail = false;

          if (error && error.response) {
            if (
              error.response.data &&
              error.response.data.code === "TENANT_UNDER_MAINTENANCE"
            ) {
              this.error = this.$trans("tenant_account_frozen_error");
            } else if (error.response.status === 403) {
              this.displayResendActiveEmail = true;
              this.error = this.$trans("please_activate_account");
            } else {
              this.error = this.$trans("email_or_password_incorrect");
            }
          } else if (
            error &&
            error.message &&
            error.message === "EXTRA_EMAIL_VERIFICATION_FAILED"
          ) {
            this.error = this.$trans("captcha_invalid_error_notification");
          } else {
            this.error = this.$trans("email_or_password_incorrect");
          }

          this.$log.error("Login Error Occurred", error);
          this.loading = false;
          this.setTenantHealthy(false);
        });
    },
    sendActivateEmail() {
      this.isSendingLinkAgain = true;
      api
        .resendActivateEmail(this.form.email)
        .then(() => {
          successNotification("account_activation_resend_link_succeeded");
        })
        .catch((error) => {
          errorNotification(
            "account_activation_resend_link_failed",
            error,
            false,
          );
        })
        .finally(() => {
          this.isSendingLinkAgain = false;
        });
    },
  },
};
</script>
