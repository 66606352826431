<template>
  <v-container
    v-if="!isRedirecting"
    id="login"
    tag="section"
    class="fill-height justify-center"
  >
    <v-row justify="center" class="fill-height">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex align-center"
        md="6"
        xl="4"
      >
        <v-img
          :src="require(`@/assets/login_illu.svg`)"
          contain
          max-width="400px"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="4"
        class="d-flex fill-height align-center justify-center"
      >
        <login-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoginForm from "./forms/LoginForm";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "CLogin",
  components: { LoginForm },
  mixins: [roleActions],
  data() {
    return {
      isRedirecting: false,
    };
  },
  computed: {
    ...mapGetters({
      firstRouteRedirectName: "auth/getFirstRouteRedirectName",
    }),
  },
  mounted() {
    this.shouldOpenDialog(
      "reset_password",
      "RESET_PASSWORD",
      this.$trans("reset_password_title"),
    );

    if (this.isUserLogged) {
      this.isRedirecting = true;
      this.$router.push({ name: this.firstRouteRedirectName });
    }
  },
  created() {
    const accountActivationErrorMessage = localStorage.getItem(
      "accountActivationErrorMessage",
    );
    if (
      accountActivationErrorMessage &&
      accountActivationErrorMessage.length > 0
    ) {
      errorNotification(accountActivationErrorMessage);
      localStorage.removeItem("accountActivationErrorMessage");
    }
  },
  methods: {
    ...mapActions({
      setTenant: "setup/setTenant",
    }),
    shouldOpenDialog(routeName, type, title) {
      if (this.$route.name === routeName) {
        import("../../components/Dialogs/dialogTypes").then((response) => {
          this.openDialog({
            type: response.default[type],
            title,
            data: { token: this.$route.params.token },
          });
        });

        this.setTenant(this.$route.params.tenant);
      }
    },
  },
};
</script>
